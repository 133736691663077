<template>
  <div>
    <el-dialog
      title="撤销预约运输单"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="30%"
    >
      <el-form ref="revokeForm" :model="revokeForm" label-width="150px" :rules="revokeRules">
        <el-form-item label="撤销原因" label-width="120" prop="canCelReason">
          <el-input
            v-model="revokeForm.canCelReason"
            type="textarea"
            :rows="6"
            placeholder="请输入原因，不超过256字符"
            maxlength="256"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button :loading="revokeLoading" type="primary" @click="submitRevoke()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { cancelAppointOrder } from '@/api/appointTransport'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    revokeId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      revokeLoading: false,
      revokeForm: { canCelReason: '' },
      revokeRules: {
        canCelReason: [{ required: true, message: '必填', trigger: 'blur' }]
      }
    }
  },
  computed: {
    visible: {
      get() {
        Object.assign(this.revokeForm, { appointOrderCode: this.revokeId })
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'visible'(val) {
      if (!val) {
        this.revokeForm.canCelReason = ''
        this.$refs.revokeForm.resetFields()
      }
    }
  },
  methods: {
    submitRevoke() {
      this.$refs.revokeForm.validate(async(valid) => {
        if (valid) {
          try {
            this.revokeLoading = true
            await cancelAppointOrder(this.revokeForm)
            this.revokeLoading = false
            this.visible = false
            // 刷新列表
            this.$emit('refresh')
          } finally {
            this.revokeLoading = false
          }
        }
      })
    }
  }
}
</script>
