<template>
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="150px" style="display: flex">
      <div class="el-lt" style="width: 85%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="预约单号" :class="$i18n.locale">
              <el-input v-model="form.appointOrderCode" :placeholder="$t('page.inputPlaceholder')" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="关联单号" :class="$i18n.locale">
              <el-input v-model="form.relationCode" :placeholder="$t('page.inputPlaceholder')" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="运输类型" :class="$i18n.locale">
              <el-select v-model="form.transportType" clearable filterable style="width:100%;" :placeholder="$t('page.selectPlaceholder')" @change="getTransType">
                <el-option
                  v-for="item in _getAllCommodityDict('SCM_APPOINT_TRANSPORT_TYPE')"
                  :key="item.val"
                  :value="item.val"
                  :label="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="送货方式" :class="$i18n.locale">
              <el-select v-model="form.deliveryMethod" clearable filterable style="width:100%;" :placeholder="$t('page.selectPlaceholder')">
                <el-option
                  v-for="item in _getAllCommodityDict('VENDOR_DELIVERY_METHOD')"
                  :key="item.val"
                  :value="item.val"
                  :label="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态" :class="$i18n.locale">
              <el-select v-model="form.stateDictList" multiple filterable collapse-tags clearable style="width:100%;" :placeholder="$t('page.selectPlaceholder')">
                <el-option
                  v-for="item in _getAllCommodityDict('SCM_APPOINT_ORDER_STATE')"
                  :key="item.val"
                  :value="item.val"
                  :label="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="始发地" :class="$i18n.locale">
              <el-select
                v-model="form.originPlaceIdList"
                clearable
                filterable
                multiple
                collapse-tags
                style="width:100%;"
                placeholder="选择此项前，请先选择运输类型"
                @visible-change="_getOriginList"
              >
                <el-option
                  v-for="item in originOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="目的地" :class="$i18n.locale">
              <el-select
                v-model="form.destinationId"
                clearable
                filterable
                multiple
                collapse-tags
                style="width:100%;"
                placeholder="选择此项前，请先选择运输类型"
                @visible-change="_getOriginList"
              >
                <el-option
                  v-for="item in destinationOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预计发货时间范围">
              <el-date-picker
                v-model="estimateDeliveryDate"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width:100% ;"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建人" :class="$i18n.locale">
              <el-select v-model="form.createByIdList" multiple clearable filterable style="width:100%;">
                <el-option v-for="item in useroptions" :key="item.id" :label="item.username" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建时间范围">
              <el-date-picker
                v-model="createDate"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width:100% ;"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预计到货时间范围">
              <el-date-picker
                v-model="estimateArrivalDate"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width:100% ;"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <router-link
              :to="{path: 'added',
                    query: { type:'add' },
                    append:true}"
            >
              <el-button v-permission="'add'" type="primary">新增</el-button>
            </router-link>
            <el-button v-permission="'import'" type="primary" class="ml-2" @click="importVisible = true">导入</el-button>
            <el-button v-permission="'confirm'" :loading="changeLoading" class="ml-2" type="primary" @click="handleChange">确认预约</el-button>
            <el-button v-permission="'exportAll'" :loading="tableDatasLoading" @click="handleExport('all')">导出</el-button>
            <el-button v-permission="'exportDetails'" :loading="tableDatasLoading" @click="handleExport('detail')">导出明细</el-button>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width: 15%">
        <el-col class="row-center">
          <el-button type="primary" class="ml-4" :loading="tableDatasLoading" @click="queryClick()">{{
            $t("page.search")
          }}</el-button>
          <el-button :loading="tableDatasLoading" @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button type="text" @click="showRowClick()">{{
            showRow ? $t("page.hide") : $t("page.expand")
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      ref="tableDatas"
      v-loading="tableDatasLoading"
      :data="tableDatas"
      :header-cell-style="{ background: '#fafafa' }"
      tooltip-effect="dark"
      max-height="600px"
      border
      @selection-change="SelectionChange"
    >
      <el-table-column type="selection" width="60" align="center" />
      <el-table-column :label="$t('page.No')" type="index" width="70" align="center" />
      <el-table-column label="预约单号" min-width="190">
        <template slot-scope="scope">
          <router-link
            :to="{
              path: 'added',
              query: { id: scope.row.appointOrderCode,type:'view' },
              append:true
            }"
            style="color: #1890ff"
          >{{
            scope.row.appointOrderCode
          }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="relationCode" label="关联单号" width="100" show-overflow-tooltip />
      <el-table-column prop="transportTypeI18" label="运输类型" width="100" />
      <el-table-column prop="deliveryMethodI18" label="送货方式" width="100" />
      <el-table-column prop="stateI18" label="状态" width="80" />
      <el-table-column prop="carNumber" label="车牌号" width="120" />
      <el-table-column prop="remark" label="备注" width="110" show-overflow-tooltip />
      <el-table-column prop="originPlaceName" label="始发地" width="90" show-overflow-tooltip />
      <el-table-column prop="originPlaceAddress" label="始发地地址" width="120" show-overflow-tooltip />
      <el-table-column prop="destinationName" label="目的地" width="90" show-overflow-tooltip />
      <el-table-column prop="destinationAddress" label="目的地地址" width="120" show-overflow-tooltip />
      <el-table-column prop="estimateDeliveryTime" label="预计发货时间" width="160" />
      <el-table-column prop="estimateArrivalTime" label="预计到货时间" width="160" />
      <el-table-column prop="totalQuantity" label="数量" width="110" />
      <el-table-column prop="totalBoxCount" label="箱数" width="110" />
      <el-table-column prop="totalWeight" label="总重量(kg)" width="120" />
      <el-table-column prop="totalVolume" label="总体积(m³)" width="110" />
      <el-table-column prop="modifyByName" label="操作人" width="100" />
      <el-table-column prop="modifyTime" label="操作时间" width="160" />
      <el-table-column prop="createByName" label="创建人" width="100" />
      <el-table-column prop="createTime" label="创建时间" width="160" />

      <el-table-column fixed="right" label="操作" width="120" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-button v-if="['WAIT_CONFIRM','CONFIRM','WAIT_PEND'].includes(scope.row.stateDict)" v-permission="'edit'" type="text" :loading="editLoading" @click="toEdit(scope.row)">修改</el-button>
          <el-button v-if="['CONFIRM','WAIT_PEND','WAIT_ORIGIN_PLACE','WAIT_DESTINATION'].includes(scope.row.stateDict)" v-permission="'revoke'" type="text" @click="handleRevoke(scope.row.appointOrderCode)">撤销</el-button>
          <el-button v-if="scope.row.stateDict==='WAIT_CONFIRM'" v-permission="'cancel'" type="text" @click="cancelPurchaseOrder(scope.row.appointOrderCode)">作废</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <Paging :pager="pager" end @pagination="pagerUpdate" />
    </div>

    <ImportFile v-model="importVisible" @refresh="queryClick" />
    <!-- 撤销 -->
    <Revoke v-model="revokeVisible" :revoke-id="revokeId" @refresh="queryClick" />
  </div>
</template>

<script>
import { getInfo } from '@/api/user'
import { usersList } from '@/api/scm-api'
import { getAppointOrder, confirmAppointOrder, invalidateAppointOrder,
  getNewVendorList, getImsWarehouse,
  appointOrderExport, appointOrderExportDetail, editAppointOrderinfo } from '@/api/appointTransport'
import Paging from '@/components/Pagination'
import ImportFile from './components/ImportFile.vue'
import Revoke from './components/Revoke.vue'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'

export default {
  components: { Paging, ImportFile, Revoke },
  mixins: [commodityInfoDict],
  data() {
    return {
      ids: [],
      tableDatasLoading: false,
      editLoading: false,
      tableDatas: [],
      multipleSelection: [],
      showRow: false, // 切换显示input
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      form: {
        appointOrderCode: '',
        relationCode: '',
        transportType: '',
        deliveryMethod: '',
        stateDictList: [],
        destinationId: [],
        originPlaceIdList: [],
        createByIdList: []
      },
      vendorOptions: [],
      useroptions: [],
      warehouseOptions: [],
      originOptions: [],
      destinationOptions: [],
      estimateDeliveryDate: [],
      createDate: [],
      estimateArrivalDate: [],
      revokeVisible: false,
      revokeId: '',
      changeLoading: false,
      importVisible: false
    }
  },
  computed: {
    returnPageListParams() {
      const { transportType, deliveryMethod } = this.form
      const [estimateDeliveryDateStart, estimateDeliveryDateEnd] = this.estimateDeliveryDate || []
      const [createDateStart, createDateEnd] = this.createDate || []
      const [estimateArrivalDateStart, estimateArrivalDateEnd] = this.estimateArrivalDate || []
      const transportTypeDictList = transportType ? [transportType] : []
      const deliveryMethodDictList = deliveryMethod ? [deliveryMethod] : []
      return Object.assign({}, this.form, { transportTypeDictList, deliveryMethodDictList },
        { estimateDeliveryDateStart, estimateDeliveryDateEnd, createDateStart, createDateEnd, estimateArrivalDateStart, estimateArrivalDateEnd })
    }
  },
  async mounted() {
    this._queryVendorList()
    this._usersList()
    this._getImsWarehouse()
    await this.handleCreateById()
    this.queryClick()
  },
  methods: {
    getTransType(e) {
      this.form.destinationId = []
      this.form.originPlaceIdList = []
      if (e === 'PURCHASE_RETURN') {
        this.originOptions = this.warehouseOptions
        this.destinationOptions = this.vendorOptions
      } else if (e === 'PURCHASE_COLLECT') {
        this.originOptions = this.vendorOptions
        this.destinationOptions = this.warehouseOptions
      } else {
        this.originOptions = []
        this.destinationOptions = []
      }
    },
    _getOriginList(val) {
      if (val) {
        if (!this.form.transportType) return this.$message.warning('请先选择运输类型')
      }
    },
    SelectionChange(val) {
      this.multipleSelection = val
    },
    async handleChange() {
      if (!this.multipleSelection.length) return this.$message.warning('请至少选择一条数据')
      if (this.multipleSelection.some(item => item.stateDict !== 'WAIT_CONFIRM')) return this.$message.warning('仅限预约单状态为待确认的数据可操作')
      const appointOrderCodeList = this.multipleSelection.map(item => item.appointOrderCode)
      try {
        this.changeLoading = true
        const { code, msg } = await confirmAppointOrder(appointOrderCodeList)
        code === 0 && this.$notify({
          message: msg,
          type: 'success'
        })
        this.changeLoading = false
        this.queryClick()
      } finally {
        this.changeLoading = false
      }
    },
    async handleExport(type) {
      const exportParams = {}
      if (Array.isArray(this.multipleSelection) && this.multipleSelection.length) {
        const appointOrderCodeList = this.multipleSelection.map(item => item.appointOrderCode)
        Object.assign(exportParams, { appointOrderCodeList })
      } else {
        Object.assign(exportParams, this.returnPageListParams)
      }
      const { code, msg } = type === 'all' ? await appointOrderExport({ params: JSON.stringify(exportParams) }) : await appointOrderExportDetail({ params: JSON.stringify(exportParams) })
      code === 0 &&
      this.$notify({
        message: msg,
        type: 'success'
      })
    },
    toEdit({ appointOrderCode, stateDict }) {
      if (['CONFIRM', 'WAIT_PEND'].includes(stateDict)) {
        this.$confirm(('该预约单已确认预约，编辑并提交修改数据后将移交至物流进行审批，请确认是否继续？'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        }).then(() => {
          this.handleEdit(appointOrderCode, stateDict)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      } else {
        this.handleEdit(appointOrderCode, stateDict)
      }
    },
    async handleEdit(appointOrderCode, stateDict) {
      try {
        this.editLoading = true
        await editAppointOrderinfo(appointOrderCode)
        this.editLoading = false
        this.$router.push({
          path: 'added',
          query: { id: appointOrderCode, type: 'edit', stateDict: stateDict },
          append: true
        })
      } finally {
        this.editLoading = false
      }
    },
    handleRevoke(id) {
      this.revokeVisible = true
      this.revokeId = id
    },
    cancelPurchaseOrder(id) {
      this.$confirm(('当前操作将作废单据，无法撤销，是否继续？'), this.$t('page.Prompt'), {
        confirmButtonText: this.$t('page.sure'),
        cancelButtonText: this.$t('title.cancel')
      }).then(async() => {
        const appointOrderCodeList = []
        appointOrderCodeList.push(id)
        const { code, msg } = await invalidateAppointOrder(appointOrderCodeList)
        code === 0 && this.$notify({
          message: msg,
          type: 'success'
        })
        this.queryClick()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.estimateDeliveryDate = []
      this.createDate = []
      this.estimateArrivalDate = []
      this.originOptions = []
      this.destinationOptions = []
      this.queryClick(0)
    },
    async queryClick() {
      try {
        this.tableDatasLoading = true
        const { datas, code } = await getAppointOrder(this.returnPageListParams, this.pager)
        const { pager, records } = datas
        this.pager = pager
        this.tableDatas = records
        code === 0 ? this.tableDatasLoading = false : ''
      } catch (err) {
        console.log(err)
      } finally {
        this.tableDatasLoading = false
      }
    },
    showRowClick() {
      this.showRow = !this.showRow
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    },
    async _usersList() {
      const { datas } = await usersList()
      this.useroptions = datas
    },
    async _getImsWarehouse() {
      const { datas } = await getImsWarehouse({ areaType: 'INTERNAL', warehouseCategory: 1, warehouseType: 'OWN' })
      this.warehouseOptions = datas?.map(item => Object.assign(item, { label: item.warehouseName, value: item.logicWarehouseCode }))
    },
    async _queryVendorList() {
      const { datas } = await getNewVendorList({})
      this.vendorOptions = datas?.map(item => Object.assign(item, { label: item.vendorName, value: item.id }))
    },
    async handleCreateById() {
      // 第一次进入自动筛选当前用户数据
      const { datas } = await getInfo()
      this.form.createByIdList = [datas.id]
    }
  }
}
</script>
